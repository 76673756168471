import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Button as SemanticButton, ButtonProps as SemanticButtonProps } from 'semantic-ui-react';

export type ButtonType =
  | 'create'
  | 'save'
  | 'cancel'
  | 'delete'
  | 'denied'
  | 'validate'
  | 'modify'
  | 'publish'
  | 'verify'
  | 'import-members'
  | 'import-file'
  | 'menu-item'
  | 'save-green'
  | 'back'
  | 'resend'
  | 'test';

const buttonPropsByType: Record<ButtonType, SemanticButtonProps> = {
  create: {
    secondary: true,
    content: 'Créer',
    labelPosition: 'left',
    icon: 'add',
  },
  save: {
    secondary: true,
    content: 'Enregistrer',
    labelPosition: 'left',
    icon: 'save',
  },
  'save-green': {
    primary: true,
    content: 'Enregistrer',
    labelPosition: 'left',
    icon: 'save',
  },
  modify: {
    secondary: true,
    content: 'Modifier',
    labelPosition: 'left',
    icon: 'write',
  },
  cancel: {
    content: 'Annuler',
  },
  back: {
    content: 'Retour',
    icon: 'arrow left',
  },
  resend: {
    content: 'Renvoyé',
    icon: 'paper plane',
  },
  delete: {
    color: 'red',
    content: 'Supprimer',
    labelPosition: 'left',
    icon: 'trash alternate outline',
  },
  denied: {
    color: 'red',
    content: 'Refuser',
    labelPosition: 'left',
    icon: 'close',
  },
  validate: {
    primary: true,
    content: 'Valider',
    labelPosition: 'left',
    icon: 'check',
  },
  publish: {
    secondary: true,
    content: 'Publier',
    labelPosition: 'left',
    icon: 'paper plane',
  },
  verify: {
    color: 'blue',
    content: 'Vérifier',
  },
  'import-members': {
    content: 'Importer des membres',
    labelPosition: 'left',
    icon: 'file alternate outline',
  },
  'import-file': {
    content: 'Choisir un fichier',
    labelPosition: 'left',
    icon: 'add',
  },
  'menu-item': {
    content: 'item',
    labelPosition: 'right',
  },
  test: {
    color: 'grey',
    content: 'Test',
    icon: 'bug',
  },
};

export interface ButtonProps extends SemanticButtonProps {
  btnType?: ButtonType;
  show?: boolean;
}

export const SharedButton: FC<ButtonProps> = ({ btnType, show, disabled, loading, className, ...buttonProps }) => {
  if (show === false) {
    return null;
  }

  const buttonTypeProps = btnType ? buttonPropsByType[btnType] : {};

  const buttonDisabled = loading || disabled;

  return (
    <SemanticButton
      className={`${className ?? ''} ${btnType ?? ''}`}
      {...buttonTypeProps}
      {...buttonProps}
      disabled={buttonDisabled}
      loading={loading}
    />
  );
};

export interface SharedButtonLinkProps extends ButtonProps {
  to: string;
}

export const SharedButtonLink: FC<SharedButtonLinkProps> = ({ to, btnType, ...buttonProps }) => {
  const linkProps =
    btnType === 'cancel' || btnType === 'back'
      ? {
          pathname: to,
          state: {
            ignorePrevent: true,
          },
        }
      : to;

  return <SharedButton as={Link} to={linkProps} btnType={btnType} {...buttonProps} />;
};
