import { navItems } from '@layout/header/nav/model';
import { UserRole } from '@shared/modules/users/model';
import * as B from 'fp-ts/boolean';
import { pipe } from 'fp-ts/function';
import React, { FC } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import * as Styled from './Nav.styles';

interface NavProps {
  role: Exclude<UserRole, UserRole.Operator>;
  bioActivation: boolean;
  open: boolean;
}

const Nav: FC<NavProps> = ({ role, bioActivation, open }) => {
  const location = useLocation();

  const realRole: Exclude<UserRole, UserRole.Operator> = pipe(
    !location.pathname.includes('board') && role === UserRole.Board,
    B.fold(
      () => role,
      () => UserRole.Estatemate,
    ),
  );

  return (
    <Styled.NavContainer role={role} isBoard={location.pathname.includes('board')} open={open}>
      <Styled.NavHeader />
      <Styled.NavList>
        {navItems[realRole].map(
          entry =>
            (entry.to !== '/bio' || (entry.to === '/bio' && bioActivation)) && (
              <Styled.NavItem key={entry.to}>
                <NavLink to={entry.to} exact={entry.exact}>
                  <img width={36} height={30} src={entry.icon} alt={entry.title} />
                  <p>{entry.title}</p>
                </NavLink>
              </Styled.NavItem>
            ),
          () => null,
        )}
      </Styled.NavList>
    </Styled.NavContainer>
  );
};

export default Nav;
