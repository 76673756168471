import { Form, useFormikContext } from 'formik';
import React, { FC } from 'react';

import Table from '@shared/components/table/Table';
import { hasError, renderDefaultErrorMessage } from '@shared/modules/form';

import { TutorialFormBody } from '../../model';
import TutorialListHeader from './header/TutorialListHeader';
import TutorialListRow from './row/TutorialListRow';

interface TutorialFormProps {}

const TutorialForm: FC<TutorialFormProps> = () => {
  const { values, handleChange, getFieldMeta } = useFormikContext<TutorialFormBody>();

  const errorGetter = hasError<TutorialFormBody>(getFieldMeta);
  const errorMessageRenderer = renderDefaultErrorMessage<TutorialFormBody>(getFieldMeta);

  return (
    <Form noValidate>
      <Table header={<TutorialListHeader />} items={values.tutorials}>
        {(tutorial, index) => (
          <TutorialListRow
            key={index}
            index={index}
            tutorial={tutorial}
            handleChange={handleChange}
            errorGetter={errorGetter}
            errorMessageRenderer={errorMessageRenderer}
          />
        )}
      </Table>
    </Form>
  );
};

export default TutorialForm;
