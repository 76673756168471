import UserMenu from '@layout/header/user/menu/UserMenu';
import { Profile } from '@modules/auth/model';
import React, { FC, useState } from 'react';
import { Popup } from 'semantic-ui-react';
import * as Styled from './HeaderUser.styles';

interface HeaderUserProps {
  profile: Profile;
}

const HeaderUser: FC<HeaderUserProps> = ({ profile }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <Styled.HeaderUserPopup
      open={isOpen}
      onOpen={handleOpen}
      onClose={handleClose}
      position="bottom right"
      flowing
      hoverable
      trigger={
        <Styled.HeaderUserContainer>
          <p>
            {profile.firstName} {profile.lastName}
          </p>

          <Styled.HeaderUserBadge>{`${profile.firstName.substr(0, 1)}${profile.lastName.substr(
            0,
            1,
          )}`}</Styled.HeaderUserBadge>
        </Styled.HeaderUserContainer>
      }>
      <Popup.Content>
        <UserMenu profile={profile} onClick={handleClose} />
      </Popup.Content>
    </Styled.HeaderUserPopup>
  );
};

export default HeaderUser;
