import { httpService, HttpTask } from '@core/http';
import * as TE from 'fp-ts/TaskEither';
import { pipe } from 'fp-ts/function';
import { downloadFile } from '@shared/utils/download';

const URI = '/admin/exports';

function exportType(type: string): HttpTask {
  return pipe(
    httpService.get<Blob>(
      `${URI}/${type}/csv`,
      {
        responseType: 'blob',
      },
      true,
    ),
    TE.chainIOK(downloadFile),
  );
}

export function exportsEstates(): HttpTask {
  return exportType('estates');
}

export function exportsEstatemates(): HttpTask {
  return exportType('estatemates');
}
