import { httpService, HttpTask } from '@core/http';
import { Tutorial } from '@modules/admin/parameters/tutorials/model';
import { CoServiceType, Equipment, PartnerType, Resolution, ServiceType } from '@shared/modules/referential/model';
import { sortListByIndexProperty } from '@shared/utils/array';
import { pipe } from 'fp-ts/function';
import * as TE from 'fp-ts/TaskEither';

const URI = '/referential';

function getReferential<T>(path: string): HttpTask<Array<T>> {
  return httpService.get(`${URI}/${path}`);
}

export function getEquipments(): HttpTask<Array<Equipment>> {
  return getReferential('equipment');
}

export function getPartnerTypes(): HttpTask<Array<PartnerType>> {
  return getReferential('partner-type');
}

export function getServiceTypes(): HttpTask<Array<ServiceType>> {
  return getReferential('service-type');
}

export function getResolutions(): HttpTask<Array<Resolution>> {
  return pipe(getReferential<Resolution>('resolution'), TE.map(sortListByIndexProperty));
}

export function getCoServiceType(): HttpTask<Array<CoServiceType>> {
  return getReferential('co-service-type');
}

export function getTutorials(): HttpTask<Array<Tutorial>> {
  return getReferential('tutorial-link');
}
