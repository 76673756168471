import { Routes, safeLazy } from '@core/router';
import { OperatorProfile } from '@modules/auth/model';
import React, { FC } from 'react';
import { Route } from 'react-router-dom';

const DashboardRoutes = safeLazy(() => import('./dashboard/routes'));
const ProfileComponent = safeLazy(() => import('./profile/DetailPage'));
const EstateComponent = safeLazy(() => import('./estate/Estate'));

interface OperatorRoutesProps {
  profile: OperatorProfile;
}

const OperatorRoutes: FC<OperatorRoutesProps> = ({ profile }) => (
  <Routes>
    <Route path="/profile">
      <ProfileComponent profile={profile} />
    </Route>
    <Route path="/:id" component={EstateComponent} />
    <Route path="/" component={DashboardRoutes} exact />
  </Routes>
);

export default OperatorRoutes;
