import { pipe } from 'fp-ts/function';
import * as A from 'fp-ts/Array';
import * as O from 'fp-ts/Option';
import * as Ord from 'fp-ts/Ord';
import { sequenceT } from 'fp-ts/Apply';

const ordByIndexProperty = pipe(
  Ord.ordNumber,
  Ord.contramap((item: { index: number }) => item.index),
);

export function sortListByIndexProperty<T extends { index: number }>(list: Array<T>): Array<T> {
  return pipe(list, A.sort(ordByIndexProperty));
}

export function updateOrder<T>(list: Array<T>, source: number, destination: number): Array<T> {
  return pipe(
    sequenceT(O.option)(A.lookup(source)(list), A.deleteAt(source)(list)),
    O.chain(([elem, list]) => A.insertAt(destination, elem)(list)),
    O.getOrElse(() => list),
  );
}
