import { Tab } from 'semantic-ui-react';
import styled from 'styled-components';

import { backgroundColor, mediaMax, primaryColor, tertiaryColor, whiteColor } from '@styles/utils';

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
`;

export const PageContentWrapper = styled.div`
  flex: 1 1 auto;
  height: 100%;
  width: 100%;
  overflow-y: auto;
`;

export const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  max-width: ${props => props.theme.breakpoints.large + 40}px;
  padding: 20px;
  margin: 0 auto;
`;

export const PageBreadCrumbContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 15px;
  padding: 15px 20px;
  border-radius: 10px;
  background: ${backgroundColor()};
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.04);
  margin-bottom: 20px;

  .ui.breadcrumb {
    font-size: 13px;

    a {
      color: ${tertiaryColor(900)};

      &:hover {
        text-decoration: underline;
      }

      ${mediaMax.mobile`
        margin-bottom: 10px;
      `};
    }

    .active.section {
      padding: 3px 5px;
      background: ${primaryColor()};
      color: ${whiteColor};
      border-radius: 4px;
      font-weight: inherit;
    }
  }
`;

export const PageTabs = styled(Tab)`
  margin-bottom: 20px;

  .ui.pointing.secondary.menu {
    border-bottom-width: 1px;

    > a.item {
      padding: 16px 25px;
      font-weight: 600;

      ${mediaMax.mobile`
        padding: 8px 12px;
      `}

      &.active {
        border-color: ${primaryColor()};
      }
    }
  }
`;

export const PageChildren = styled.div`
  flex: 1 1 auto;
  height: 100%;
  padding-bottom: 20px;
`;

export const PageBottomBar = styled.div`
  background: ${backgroundColor()};
  box-shadow: 0 -2px 6px 0 rgba(0, 0, 0, 0.04);

  > div {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    max-width: ${props => props.theme.breakpoints.large + 40}px;
    padding: 0 15px;

    ${mediaMax.tablet`
    padding: 0 5px;
  `};

    .button {
      margin-right: 7px !important;
    }
  }
`;

export const PageBottomBarLeft = styled.div`
  display: flex;
  flex: 1 1 auto;
  height: 100%;
  align-items: center;
  justify-content: flex-start;
  padding: 7px 0;
  ${mediaMax.mobile`
    > button, a {
      width: 100%;
    }
  `}
`;

export const PageBottomBarRight = styled.div`
  display: flex;
  flex: 1 1 auto;
  height: 100%;
  align-items: center;
  justify-content: flex-end;
  padding: 7px 0;
  ${mediaMax.mobile`
    > button, a {
      width: 100%;
    }
  `}
`;
