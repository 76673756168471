import { httpService, HttpTask } from '@core/http';
import { FileUploaded } from '@shared/model/file';
import {
  DocumentFoldersItemId,
  DocumentRootFolder,
  DocumentServiceBody,
  DocumentView,
} from '@shared/modules/documents/folder/model';
import { sortListByIndexProperty } from '@shared/utils/array';
import { pipe } from 'fp-ts/function';
import * as TE from 'fp-ts/TaskEither';
import { createFormData } from '@shared/utils/formData';

const URI = '/board/documents';

function getDocumentsT<T>(id?: DocumentFoldersItemId): HttpTask<T> {
  return id ? httpService.get(`${URI}?folder_id=${id}`) : httpService.get(`${URI}`);
}

export function getDocuments(id: DocumentFoldersItemId): HttpTask<DocumentView> {
  return pipe(
    getDocumentsT<DocumentView>(id),
    TE.map(res => {
      res.documents = sortListByIndexProperty(res.documents);
      return res;
    }),
  );
}

export function uploadDocument(body: File): HttpTask<FileUploaded> {
  return httpService.post(`${URI}/file`, createFormData({ body }));
}

export function saveDocument(
  currentFolderId: DocumentFoldersItemId,
  params: DocumentServiceBody,
): HttpTask<DocumentView> {
  return httpService.post(`${URI}/${currentFolderId}`, params);
}

export function getRootFolder(): HttpTask<DocumentRootFolder> {
  return httpService.get(`${URI}/root`);
}
