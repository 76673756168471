import { ReactNode } from 'react';

import isEqual from 'lodash.isequal';

import * as O from 'fp-ts/Option';

import { Filter } from '@shared/modules/filter';

export const DEFAULT_RANGE_SIZE = 50;

export interface RangeCursor {
  startIndex?: number;
  endIndex?: number;
}

export const defaultRangeCursor: RangeCursor = { startIndex: 0, endIndex: DEFAULT_RANGE_SIZE - 1 };

export interface RangeResult<T, F extends Filter = {}> {
  total: number;
  items: Array<T>;
  filter: F;
  sort: string | null;
  startIndex: number;
  endIndex: number;
}

export class Range<T, F extends Filter = {}> {
  constructor(
    readonly items: ReadonlyMap<number, T>,
    readonly total: number,
    readonly loading: boolean,
    readonly filter?: F,
  ) {}

  merge(newRange: Range<T, F>): Range<T, F> {
    if (isEqual(this.filter, newRange.filter)) {
      return new Range<T, F>(
        new Map<number, T>([...Array.from(this.items.entries()), ...Array.from(newRange.items.entries())]),
        newRange.total,
        false,
        newRange.filter,
      );
    }

    return newRange;
  }

  has(index: number): boolean {
    return this.items.has(index);
  }

  get(index: number): O.Option<T> {
    return O.fromNullable(this.items.get(index));
  }

  setLoading(loading: boolean = true): Range<T, F> {
    return new Range(this.items, this.total, loading, this.filter);
  }

  toList(): Array<T> {
    return Array.from(this.items.values());
  }

  map<B>(fa: (a: T) => B): Range<B, F> {
    return new Range(
      new Map<number, B>(Array.from(this.items, ([key, value]) => [key, fa(value)])),
      this.total,
      this.loading,
      this.filter,
    );
  }

  static fromRangeResult<T, F extends Filter = {}>(result: RangeResult<T, F>) {
    return new Range(
      new Map<number, T>(result.items.map((item, i) => [i + result.startIndex, item])),
      result.total,
      false,
      result.filter,
    );
  }

  static fromArray<T, F extends Filter = {}>(list: Array<T>): Range<T, F> {
    return new Range<T, F>(new Map<number, T>(list.map((item, i) => [i, item])), list.length, false);
  }

  static default<T, F extends Filter = {}>() {
    return new Range<T, F>(new Map<number, T>(), 0, true);
  }
}

export type VirtualizedTableRowLinkBuilder<T> = (item: T, index: number) => string | null;

export type VirtualizedTableRowClickHandler<T> = (item: T, index: number) => void;

export type VirtualizedTableKeyMapper<T> = (item: T, index: number) => string;

export interface VirtualizedTableProps<T> {
  range: Range<T>;
  loadMore: (cursor: RangeCursor) => Promise<unknown>;
  emptyMessage?: string;
  noFilter?: boolean;
  header?: ReactNode;
  rowLinkBuilder?: VirtualizedTableRowLinkBuilder<T>;
  onRowClick?: VirtualizedTableRowClickHandler<T>;
  openRowLinkInNewTab?: boolean;
  keyMapper?: VirtualizedTableKeyMapper<T>;
  children: (item: T, index: number) => ReactNode;
}

export function defaultKeyMapper<T extends { id: string }>(item: T): string {
  return item.id;
}

export interface VirtualizedGridProps<T> {
  range: Range<T>;
  loadMore: (cursor: RangeCursor) => Promise<unknown>;
  chunkSize: number;
  children: (items: Array<T>) => ReactNode;
}
