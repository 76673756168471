import React, { FC } from 'react';
import * as Styled from './ExportsPage.styles';
import * as ExportsService from './service';
import Page, { PageProps } from '@layout/page/Page';
import { Button } from 'semantic-ui-react';
import { useSendTask } from '@core/http/hooks';

const ExportsPage: FC = () => {
  const [estatesLoading, exportEstates] = useSendTask(ExportsService.exportsEstates);
  const [loading, exportEstatemates] = useSendTask(ExportsService.exportsEstatemates);

  const handleExportEstates = () => exportEstates();
  const handleExportEstatemates = () => exportEstatemates();

  const pageProps: PageProps = {
    breadcrumbs: { title: 'Exports' },
  };

  return (
    <Page {...pageProps}>
      <Styled.ExportsPageContainer>
        <Styled.ExportsPageTitle>ASL</Styled.ExportsPageTitle>

        <Button loading={estatesLoading} onClick={handleExportEstates} primary>
          Exporter au format .csv
        </Button>

        <Styled.ExportsPageTitle>Membres</Styled.ExportsPageTitle>

        <Button loading={loading} onClick={handleExportEstatemates} primary>
          Exporter au format .csv
        </Button>
      </Styled.ExportsPageContainer>
    </Page>
  );
};

export default ExportsPage;
