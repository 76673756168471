import theme from '../theme';

import { pipe } from 'fp-ts/function';
import * as R from 'fp-ts/Record';
import { BreakPoints, css, DefaultTheme, ThemedCssFunction } from 'styled-components';

export const mediaMin = pipe(
  theme.breakpoints,
  R.reduceWithIndex(
    {} as Readonly<Record<Extract<keyof BreakPoints, string>, ThemedCssFunction<DefaultTheme>>>,
    (key, acc) => ({
      ...acc,
      [key]: (first: any, ...interpolations: any[]) => css`
        @media screen and (min-width: ${props => props.theme.breakpoints[key] + 1}px) {
          ${css(first, ...interpolations)}
        }
      `,
    }),
  ),
);

export const mediaMax = pipe(
  theme.breakpoints,
  R.reduceWithIndex(
    {} as Readonly<Record<Extract<keyof BreakPoints, string>, ThemedCssFunction<DefaultTheme>>>,
    (key, acc) => ({
      ...acc,
      [key]: (first: any, ...interpolations: any[]) => css`
        @media screen and (max-width: ${props => props.theme.breakpoints[key]}px) {
          ${css(first, ...interpolations)}
        }
      `,
    }),
  ),
);
