import { mediaMax, primaryColor, whiteColor } from '@styles/utils';
import { Popup } from 'semantic-ui-react';
import styled from 'styled-components';

export const HeaderUserContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  > p {
    padding-right: 10px;
    margin: 0;
  }

  ${mediaMax.tablet`
    > p {
      display: none;
    }
  `}
`;

export const HeaderUserBadge = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 34px;
  width: 34px;
  padding-top: 3px;
  background: ${primaryColor(700)};
  border-radius: 100%;
  line-height: 1;
  font-size: 14px;
  color: ${whiteColor};
  font-weight: bold;
  text-transform: uppercase;
`;

export const HeaderUserPopup = styled(Popup)`
  padding: 0 !important;

  .ui.menu {
    border: none !important;
    box-shadow: none;
  }
`;
