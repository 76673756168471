import React, { FC } from 'react';

import * as Styled from './HeaderBurger.styles';

interface HeaderBurgerProps {
  active: boolean;
  handleToggle: () => void;
}

const HeaderBurger: FC<HeaderBurgerProps> = ({ active, handleToggle }) => {
  return (
    <Styled.HeaderBurgerContainer onClick={handleToggle}>
      <Styled.HeaderBurgerLines active={active} />
    </Styled.HeaderBurgerContainer>
  );
};

export default HeaderBurger;
