import * as yup from 'yup';

import { Tutorial, TutorialFormBody } from './model';

const tutorialSchema: yup.SchemaOf<Tutorial> = yup.object().shape({
  link: yup.string().url("Le format d'url est incorrect").nullable().default(null),
  location: yup.string().required() as any,
  id: yup.string().required() as any,
});

export const saveTutorialSchema: yup.SchemaOf<TutorialFormBody> = yup.object().shape({
  tutorials: yup.array().of(tutorialSchema) as any,
});
