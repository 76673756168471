import { PrimaryColor, SecondaryColor, TertiaryColor, BackgroundColor, Colors, DefaultTheme } from 'styled-components';

const primary: PrimaryColor = {
  100: '#c9fbeb',
  200: '#a5e8cf',
  300: '#81d6b4',
  400: '#5dc398',
  500: '#39b07c',
  600: '#02b885',
  700: '#01956c',
  800: '#017353',
  900: '#00503a',
};

const secondary: SecondaryColor = {
  100: '#fbf6f0',
  200: '#ffe9d7',
  400: '#f5ca4f',
  500: '#fab710',
  600: '#f29814',
  700: '#d78025',
  800: '#bb681f',
  900: '#a05000',
};

const tertiary: TertiaryColor = {
  100: '#ffffff',
  200: '#cacbce',
  300: '#ababb1',
  400: '#777774',
  500: '#5b5a5a',
  600: '#43454e',
  700: '#2d3336',
  800: '#222222',
  900: '#000000',
};

const background: BackgroundColor = {
  100: '#ffffff',
  200: '#f9fcff',
  300: '#f0f3fc',
  400: '#64b5e7',
  500: '#6b9ddc',
  600: '#587bcc',
  700: '#4b548e',
  800: '#3d4b96',
  900: '#343d6f',
};

const colors: Colors = {
  primary,
  secondary,
  tertiary,
  background,
  black: tertiary[900],
  white: tertiary[100],
  dark: tertiary[800],
  error: '#f35858',
};

const theme: DefaultTheme = {
  colors,
  breakpoints: {
    large: 1280,
    desktop: 960,
    tablet: 760,
    mobile: 560,
  },
};

export default theme;
