import { errorColor, mediaMax, primaryColor } from '@styles/utils';
import { createGlobalStyle } from 'styled-components';

export const ToastsStyles = createGlobalStyle`
  .Toastify__toast-container {
    z-index: 100000 !important;
    
    .asl-toast {
      min-height: 50px;
      padding: 10px;
      border-radius: 10px;

      .Toastify__toast-icon svg {
        fill: #ffffff;
      }
      
      &.Toastify__toast--success {
        color: #ffffff;
        background: ${primaryColor()};
      }
      
      &.Toastify__toast--error {
        color: #ffffff;
        background: ${errorColor};
      }

      ${mediaMax.large`
        margin: 20px;
      `}
    }
  }
`;
