import { css } from 'styled-components';

import { primaryColor } from '../utils';

export const datePickerOverwrite = css`
  .react-next-dates {
    --color-primary: ${primaryColor()};

    &.popper {
      z-index: 11000;
    }
  }
`;
