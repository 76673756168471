import styled, { css } from 'styled-components';
import { blackColor, mediaMin } from '@styles/utils';

export const HeaderBurgerContainer = styled.button`
  position: fixed;
  width: 30px;
  height: 30px;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  z-index: 1001;
  top: 20px;
  left: 20px;

  ${mediaMin.large`
    display: none;
  `}
`;

export const HeaderBurgerLines = styled.div<{ active: boolean }>`
  width: 100%;
  top: 4px;

  &,
  &:before,
  &:after {
    position: absolute;
    left: 0;
    height: 2px;
    background: ${blackColor};
    transition: 0.2s top, 0.1s left, 0.2s transform, 0.4s background-color 0.2s;
  }

  &:before,
  &:after {
    content: '';
    display: block;
    width: 80%;
  }

  &:before {
    top: 10px;
  }

  &:after {
    top: 20px;
  }

  ${props =>
    props.active &&
    css`
      &,
      &:after,
      &:before {
        transition: 0.2s background-color, 0.2s top, 0.2s left, 0.2s transform 0.15s;
      }
      & {
        background-color: transparent;
      }
      &:before,
      &:after {
        top: 10px;
        left: 2px;
      }
      &:before {
        transform: rotate(-45deg);
      }
      &:after {
        transform: rotate(45deg);
      }
    `}
`;
