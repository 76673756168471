import { Routes, safeLazy } from '@core/router';
import { AdminProfile } from '@modules/auth/model';
import React, { FC } from 'react';
import { Route } from 'react-router-dom';
import TutorialsRoute from './parameters/tutorials/routes';
import ExportsPage from '@modules/admin/exports/ExportsPage';

const DashboardRoutes = safeLazy(() => import('./dashboard/routes'));

const EstatesRoutes = safeLazy(() => import('./estates/routes'));

const OperatorsRoutes = safeLazy(() => import('./operators/routes'));

const AdminProfileRoutes = safeLazy(() => import('./profile/routes'));

const UserAdminsRoutes = safeLazy(() => import('./admins/routes'));

const EquipmentRoutes = safeLazy(() => import('./parameters/equipment/routes'));
const PartnerTypeRoutes = safeLazy(() => import('./parameters/partner-type/routes'));
const ServiceTypeRoutes = safeLazy(() => import('./parameters/service-type/routes'));
const ResolutionRoutes = safeLazy(() => import('./parameters/resolution/routes'));
const BioUniverseRoutes = safeLazy(() => import('./universes/bio/routes'));
const ShareUniverseRoutes = safeLazy(() => import('./universes/share/routes'));
const CoServiceTypeUniverseRoutes = safeLazy(() => import('./universes/co-service-type/routes'));

interface AdminRoutesProps {
  profile: AdminProfile;
}

const AdminRoutes: FC<AdminRoutesProps> = ({ profile }) => (
  <Routes>
    <Route path="/estates" component={EstatesRoutes} />

    <Route path="/operators" component={OperatorsRoutes} />

    <Route path="/profile" exact>
      <AdminProfileRoutes profile={profile} />
    </Route>

    <Route path="/admins" component={UserAdminsRoutes} />

    <Route path="/equipment" component={EquipmentRoutes} />
    <Route path="/partner-type" component={PartnerTypeRoutes} />
    <Route path="/service-type" component={ServiceTypeRoutes} />
    <Route path="/resolution" component={ResolutionRoutes} />
    <Route path="/bio" component={BioUniverseRoutes} />
    <Route path="/share" component={ShareUniverseRoutes} />
    <Route path="/co-service-type" component={CoServiceTypeUniverseRoutes} />
    <Route path="/tutorials" component={TutorialsRoute} />
    <Route path="/exports" component={ExportsPage} />
    <Route path="/" component={DashboardRoutes} exact />
  </Routes>
);

export default AdminRoutes;
