import { Tutorial, TutorialURLLocation } from '@modules/admin/parameters/tutorials/model';
import { useAuthContext } from '@modules/auth/context';
import { Profile } from '@modules/auth/model';
import { UserRole } from '@shared/modules/users/model';
import { renderOptional } from '@shared/utils/render';
import * as A from 'fp-ts/Array';
import { pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import React, { FC } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Icon, Menu } from 'semantic-ui-react';
import * as Styled from './UserMenu.styles';

function getTutorialLink(tutorials: Array<Tutorial>, location: TutorialURLLocation): O.Option<string> {
  return pipe(
    tutorials,
    A.findFirst(tutorial => tutorial.location === location),
    O.chainNullableK(tutorial => tutorial.link),
  );
}

interface MenuClickableProps {
  onClick?: () => void;
}

interface MenuProps extends MenuClickableProps {
  tutorialURL: O.Option<string>;
}

const AdminMenu: FC<MenuProps> = ({ tutorialURL, onClick }) => {
  const { logout } = useAuthContext();

  return (
    <Menu onClick={onClick} vertical>
      <Menu.Item>
        <Menu.Menu>
          <Menu.Item as={NavLink} to="/profile">
            Mon compte
          </Menu.Item>
          <Menu.Item as={NavLink} to="/admins">
            Mes utilisateurs
          </Menu.Item>
        </Menu.Menu>
      </Menu.Item>
      <Menu.Item>
        <Menu.Header>Paramètres</Menu.Header>
        <Menu.Menu>
          <Menu.Item as={NavLink} to="/equipment">
            Types d'équipement
          </Menu.Item>
          <Menu.Item as={NavLink} to="/partner-type">
            Types de partenaire
          </Menu.Item>
          <Menu.Item as={NavLink} to="/service-type">
            Types de prestation
          </Menu.Item>
          <Menu.Item as={NavLink} to="/resolution">
            Liste des résolutions
          </Menu.Item>
          <Menu.Item as={NavLink} to="/tutorials">
            Tutoriels
          </Menu.Item>
        </Menu.Menu>
      </Menu.Item>
      <Menu.Item>
        <Menu.Header>Univers</Menu.Header>
        <Menu.Menu>
          <Menu.Item as={NavLink} to="/bio">
            Co Bio - fiches
          </Menu.Item>
          <Menu.Item as={NavLink} to="/share">
            Co Partage - fiches
          </Menu.Item>
          <Menu.Item as={NavLink} to="/co-service-type">
            Co service - types
          </Menu.Item>
        </Menu.Menu>
      </Menu.Item>
      {renderOptional(tutorialURL, tutorialURL => (
        <Menu.Item as="a" href={tutorialURL} target="_blank" rel="noopener noreferrer">
          Besoin d'aide
          <Icon name="help" color="green" />
        </Menu.Item>
      ))}
      <Menu.Item onClick={logout}>
        Déconnexion
        <Icon name="sign-out alternate" color="red" />
      </Menu.Item>
    </Menu>
  );
};

const OperatorMenu: FC<MenuClickableProps> = ({ onClick }) => {
  const { logout } = useAuthContext();

  return (
    <Menu onClick={onClick} vertical>
      <Menu.Item as={NavLink} to="/profile">
        Mon compte
      </Menu.Item>
      <Menu.Item onClick={logout}>
        Déconnexion
        <Icon name="sign-out alternate" color="red" />
      </Menu.Item>
    </Menu>
  );
};

const EstatemateMenu: FC<MenuProps> = ({ tutorialURL, onClick }) => {
  const { logout } = useAuthContext();

  return (
    <Menu onClick={onClick} vertical>
      <Menu.Item as={NavLink} to="/profile">
        Mon compte
      </Menu.Item>
      {renderOptional(tutorialURL, tutorialURL => (
        <Menu.Item as="a" href={tutorialURL} target="_blank" rel="noopener noreferrer">
          Besoin d'aide
          <Icon name="help" color="green" />
        </Menu.Item>
      ))}
      <Menu.Item onClick={logout}>
        Déconnexion
        <Icon name="sign-out alternate" color="red" />
      </Menu.Item>
    </Menu>
  );
};

const BoardMenu: FC<MenuProps> = ({ tutorialURL, onClick }) => {
  const location = useLocation();

  const { logout } = useAuthContext();

  return (
    <Styled.ShowItemOnlyOnDesktop onClick={onClick} vertical>
      {location.pathname.includes('board') ? (
        <Menu.Item as={NavLink} to="/">
          Mon espace membre
        </Menu.Item>
      ) : (
        <>
          <Menu.Item as={NavLink} to="/profile">
            Mon compte
          </Menu.Item>
          <Menu.Item as={NavLink} to="/board" className="onlyDesktop">
            Mon espace bureau
          </Menu.Item>
        </>
      )}
      {renderOptional(tutorialURL, tutorialURL => (
        <Menu.Item as="a" href={tutorialURL} target="_blank" rel="noopener noreferrer">
          Besoin d'aide
          <Icon name="help" color="green" />
        </Menu.Item>
      ))}
      <Menu.Item onClick={logout}>
        Déconnexion
        <Icon name="sign-out alternate" color="red" />
      </Menu.Item>
    </Styled.ShowItemOnlyOnDesktop>
  );
};

interface UserMenuProps {
  profile: Profile;
  onClick?: () => void;
}

const UserMenu: FC<UserMenuProps> = ({ profile, onClick }) => {
  const { tutorials } = profile;

  switch (profile.role) {
    case UserRole.Admin:
      return <AdminMenu tutorialURL={getTutorialLink(tutorials, TutorialURLLocation.ADMIN)} onClick={onClick} />;
    case UserRole.Operator:
      return <OperatorMenu onClick={onClick} />;
    case UserRole.Estatemate:
      return (
        <EstatemateMenu tutorialURL={getTutorialLink(tutorials, TutorialURLLocation.ESTATEMATE)} onClick={onClick} />
      );
    case UserRole.Board:
      return <BoardMenu tutorialURL={getTutorialLink(tutorials, TutorialURLLocation.BOARD)} onClick={onClick} />;
    default:
      return null;
  }
};

export default UserMenu;
