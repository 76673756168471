import styled from 'styled-components';

import { backgroundColor, mediaMax, rgba, tertiaryColor } from '@styles/utils';

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 10px 0 rgb(0 0 0 / 4%);
`;

export const TableContent = styled.div`
  position: relative;
  flex: 1 1 auto;
`;

export const TableHeader = styled.div`
  display: grid;
  align-items: center;
  padding: 15px;
  background: ${backgroundColor()};
  border-bottom: 1px solid ${rgba(tertiaryColor(200), 0.5)};
  font-size: 12px;
  font-weight: 600;
  color: ${tertiaryColor(300)};
  text-transform: uppercase;
  border-radius: 10px 10px 0 0;

  p {
    margin: 0;
  }
`;

export const TableRow = styled.div`
  display: grid;
  height: 100%;
  align-items: center;
  padding: 10px 15px;

  p {
    margin: 0;
  }

  background: ${backgroundColor()};
  font-size: 14px;
  color: ${tertiaryColor(900)};
  text-decoration: none;

  &:not(:last-child) {
    border-bottom: 1px solid ${rgba(tertiaryColor(200), 0.5)};
  }

  &:last-child {
    border-radius: 0 0 10px 10px;
  }

  ${mediaMax.tablet`
    padding: 10px;
  `};
`;

export const TableNoRow = styled(TableRow)`
  display: flex;
  align-items: center;
  height: 50px;
  padding: 0 20px;
  will-change: unset;
`;
