import styled from 'styled-components';

import { backgroundColor, mediaMax } from '@styles/utils';

export const HeaderContainer = styled.header``;

export const HeaderTop = styled.div`
  height: 70px;
  background: ${backgroundColor()};
  border-bottom: 1px solid #f2f2f2;
`;

export const HeaderTopContent = styled.div`
  display: flex;
  align-items: center;
  max-width: ${props => props.theme.breakpoints.large + 20}px;
  height: 100%;
  padding: 0 20px 0 10px;
  margin: 0 auto;

  > a {
    display: inline-flex;
    align-items: center;
    height: 100%;

    > img {
      width: 231px;
      flex: 0 0 231px;
      user-select: none;

      ${mediaMax.mobile`
        width: 180px;
      `}
    }
  }

  ${mediaMax.large`
    padding: 0 20px 0 60px;
  `}
`;

export const Role = styled.div<{ background: string }>`
  margin-left: 10px;
  padding: 0 10px;
  background: ${props => props.background};
  color: #ffffff;
  font-size: 11px;
  font-weight: bold;
  border-radius: 100px;
  text-transform: uppercase;

  ${mediaMax.tablet`
    display: none;
  `}
`;

export const HeaderTopRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1 1 auto;
  height: 100%;
`;
