import { Routes, safeLazy } from '@core/router';
import React, { FC } from 'react';
import { Route, Redirect, withRouter } from 'react-router-dom';
import * as DocumentService from './documents/service';
import { renderHttpRemoteData } from '@shared/utils/render';
import { useFetchTask } from '@core/http/hooks';

const DashboardRoutes = safeLazy(() => import('./dashboard/routes'));
const EstateRoutes = safeLazy(() => import('./estate/routes'));
const EstatemateRoutes = safeLazy(() => import('./estatemates/routes'));
const DocumentsComponent = safeLazy(() => import('./documents/ListPage'));
const ArchivesComponent = safeLazy(() => import('./archives/ListPage'));
const FinanceComponent = safeLazy(() => import('./finance/Finance'));
const AssemblyComponent = safeLazy(() => import('./assembly/Assembly'));
const SharesComponent = safeLazy(() => import('./share/Shares'));
const BioComponent = safeLazy(() => import('./bio/ListPage'));

const BoardRoutes: FC = () => {
  const [getRootFolder] = useFetchTask(DocumentService.getRootFolder);

  return renderHttpRemoteData(getRootFolder, rootFolder => {
    const docPath = `/board/documents/${rootFolder.id}`;
    return (
      <Routes>
        <Route path="/board/estate" component={EstateRoutes} />
        <Route path="/board/documents/:id" component={withRouter(DocumentsComponent)} />
        <Route path="/board/documents" exact>
          <Redirect to={docPath} />
        </Route>
        <Route path="/board/archives" component={ArchivesComponent} />
        <Route path="/board/estatemates" component={EstatemateRoutes} />
        <Route path="/board/finance" component={FinanceComponent} />
        <Route path="/board/assembly" component={AssemblyComponent} />
        <Route path="/board/shares" component={SharesComponent} />
        <Route path="/board/bio" component={BioComponent} />
        <Route path="/board" component={DashboardRoutes} exact />
      </Routes>
    );
  });
};

export default BoardRoutes;
