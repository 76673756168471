import React, { ReactNode } from 'react';

import { TableContainer, TableContent, TableHeader, TableNoRow, TableRow } from './Table.styles';

import * as NEA from 'fp-ts/NonEmptyArray';
import { renderOptional } from '@shared/utils/render';

interface TableProps<A> {
  items: Array<A>;
  header?: ReactNode;
  className?: string;
  emptyMessage?: string;
  children: (item: A, index: number) => ReactNode;
}

function Table<A>({ items, header, className, emptyMessage, children }: TableProps<A>) {
  return (
    <TableContainer className={className}>
      {header}
      <TableContent>
        {renderOptional(
          NEA.fromArray(items),
          items => (
            <>{items.map(children)}</>
          ),
          () => (
            <TableNoRow>{emptyMessage ?? 'Aucune donnée à afficher.'}</TableNoRow>
          ),
        )}
      </TableContent>
    </TableContainer>
  );
}

export { TableHeader, TableRow };

export default Table;
