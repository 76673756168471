import React, { ChangeEventHandler, FC } from 'react';
import { Form, Input } from 'semantic-ui-react';

import { filterEmptyStringToNullable } from '@shared/utils/string';

import { Tutorial, URLLocationLabel } from '../../../model';
import * as Styled from './TutorialListRow.styles';

interface TutorialListRowProps {
  tutorial: Tutorial;
  index: number;
  handleChange: ChangeEventHandler<HTMLInputElement>;
  errorGetter: (name: string) => boolean;
  errorMessageRenderer: (name: string) => React.ReactNode;
}

const TutorialListRow: FC<TutorialListRowProps> = ({
  tutorial,
  index,
  handleChange,
  errorGetter,
  errorMessageRenderer,
}) => {
  return (
    <Styled.TutorialListRowContainer>
      <Form.Field required>
        <Input
          id={`tutorials[${index}].link`}
          value={filterEmptyStringToNullable(tutorial.link)}
          placeholder="URL"
          onChange={handleChange}
          error={errorGetter(`tutorials[${index}].link`)}
        />
        {errorMessageRenderer(`tutorials[${index}].link`)}
      </Form.Field>
      <p>{URLLocationLabel[tutorial.location]}</p>
    </Styled.TutorialListRowContainer>
  );
};

export default TutorialListRow;
