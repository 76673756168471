import styled, { DefaultTheme, ThemeProps } from 'styled-components';

import { UserRole } from '@shared/modules/users/model';
import {
  backgroundColor,
  mediaMax,
  primaryColor,
  shouldForwardPropHelper,
  tertiaryColor,
  whiteColor,
} from '@styles/utils';

interface RoleProps {
  role: Exclude<UserRole, UserRole.Operator>;
  isBoard: boolean;
}

function getNavBackground(props: ThemeProps<DefaultTheme> & RoleProps): string {
  switch (props.role) {
    case UserRole.Estatemate:
      return backgroundColor(900)(props);
    case UserRole.Board:
      if (props.isBoard) return primaryColor(900)(props);
      else return backgroundColor(900)(props);

    default:
      return tertiaryColor(600)(props);
  }
}

export const NavContainer = styled.nav.withConfig<RoleProps & { open: boolean }>({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    shouldForwardPropHelper(prop, defaultValidatorFn, ['role', 'isBoard']),
})`
  height: 70px;
  background: ${props => getNavBackground(props)};

  ${props => mediaMax.large`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    transform: translateX(${props.open ? '0' : '-100%'});
    transition: transform 0.15s linear;
    overflow: auto;
  `};
`;

export const NavHeader = styled.div`
  display: none;
  height: 70px;
  background: ${whiteColor};

  ${mediaMax.large`
    display: block;
  `}
`;

export const NavList = styled.ul`
  height: 100%;
  padding: 0 20px;
  margin: 0;
  display: flex;
  justify-content: center;
  list-style: none;

  ${mediaMax.large`
    flex-direction: column;
    justify-content: flex-start;
    padding: 20px;
  `}
`;

export const NavItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0 18px;

  > a {
    text-align: center;

    > p {
      padding: 1px 5px;
      font-size: 13px;
      color: ${whiteColor};
      border-radius: 4px;
      text-transform: uppercase;
      background: rgba(255, 255, 255, 0);
      transition: background 0.15s ease-in-out;
    }

    &.active {
      > p {
        background: rgba(255, 255, 255, 0.2);
      }
    }
  }

  ${mediaMax.large`
    height: auto;
    padding: 15px 0;
  `}
`;
