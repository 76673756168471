import { RefObject, useCallback, useRef, useState } from 'react';
import { EnhanceFormik } from './';
import { FormikState } from 'formik';

interface ExternalSubmitValue<T> {
  handleFormChanged: () => void;
  resetFormChanged: (nextState?: Partial<FormikState<T>>) => void;
  handleSubmit: () => void;
  formRef: RefObject<EnhanceFormik<T>>;
  showCancelButton: boolean;
}

export function useExternalSubmitButton<T = any>(): ExternalSubmitValue<T> {
  const [hasFormChanged, setFormChanged] = useState<boolean>(false);

  const formRef = useRef<EnhanceFormik<T>>(null);

  const handleFormChanged = useCallback(() => {
    setFormChanged(true);
  }, []);

  const resetFormChanged = useCallback((nextState?: Partial<FormikState<T>>) => {
    formRef.current?.resetForm(nextState);
    setFormChanged(false);
  }, []);

  const handleSubmit = useCallback(() => {
    if (formRef.current && formRef.current.form) {
      formRef.current.form.submitForm();
    } else {
      console.warn('No form ref to submit !');
    }
  }, []);

  return {
    handleFormChanged,
    resetFormChanged,
    handleSubmit,
    formRef,
    showCancelButton: hasFormChanged,
  };
}
