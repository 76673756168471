import { createGlobalStyle } from 'styled-components';
import { datePickerOverwrite } from '@styles/global/date-picker';
import { primaryColor } from '@styles/utils';

export const GlobalStyles = createGlobalStyle`
  ${datePickerOverwrite}
  
  html, body {
    height: 100%;
    overflow: hidden;
  }
  
  #root {
    height: 100%;
    overflow: hidden;
  }
  
  ::selection {
    background: ${primaryColor(100)};
  }
`;
