import React, { FC, useEffect } from 'react';
import ErrorPage from '@shared/components/error-page/ErrorPage';

import * as Sentry from '@sentry/react';
import { HttpError } from '@core/http';

import * as O from 'fp-ts/Option';
import { Profile } from '@modules/auth/model';
import { pipe } from 'fp-ts/function';

function getSentryUser(profile: O.Option<Profile>): Sentry.User {
  return pipe(
    profile,
    O.fold<Profile, Sentry.User>(
      () => ({ username: 'Not authenticated' }),
      p => ({
        id: p.id,
        role: p.role,
      }),
    ),
  );
}

interface SentryProviderProps {
  profile: O.Option<Profile>;
}

const SentryProvider: FC<SentryProviderProps> = ({ profile, children }) => {
  useEffect(() => {
    Sentry.configureScope(scope => {
      scope.setUser(getSentryUser(profile));
    });
  }, [profile]);

  return <Sentry.ErrorBoundary fallback={<ErrorPage error={HttpError.default} />}>{children}</Sentry.ErrorBoundary>;
};

export default SentryProvider;
