import React, { FC } from 'react';

import { Stepper } from '../Page';
import * as Styled from './PageSteps.styles';

function isStepValidate(record: Record<any, number>, stepValue: string, currentValue: string): boolean {
  return record[stepValue] <= record[currentValue];
}

function isStepClickable(record: Record<any, number>, stepValue: string, currentValue: string): boolean {
  return record[stepValue] <= record[currentValue];
}

interface PageStepsProps {
  stepper: Stepper;
}

const PageSteps: FC<PageStepsProps> = ({ stepper }) => {
  return (
    <Styled.PageStepsContainer>
      {stepper.steps.map((value, index) => (
        <Styled.PageStepContainer
          key={index}
          to={value.to}
          exact={value.exact}
          activeClassName="active"
          $isValidate={isStepValidate(stepper.stateIndexRecord, value.stepValueCheck, stepper.currentState)}
          $isClickable={isStepClickable(stepper.stateIndexRecord, value.stepValueClick, stepper.currentState)}
        >
          <Styled.PageStepStatus>
            <Styled.PageStepArrow show={index < stepper.steps.length - 1} numberOfSteps={stepper.steps.length} />
          </Styled.PageStepStatus>
          <span>{value.title}</span>
        </Styled.PageStepContainer>
      ))}
    </Styled.PageStepsContainer>
  );
};

export default PageSteps;
