import { TableRow } from '@shared/components/table/Table';
import styled from 'styled-components';

export const TutorialListRowContainer = styled(TableRow)`
  grid-template-columns: 2fr 1fr;
  grid-column-gap: 10px;

  div {
    width: 100%;
    padding-right: 50px;
  }
`;
