import { mediaMax } from '@styles/utils';
import { Menu } from 'semantic-ui-react';
import styled from 'styled-components';

export const ShowItemOnlyOnDesktop = styled(Menu)`
  .onlyDesktop {
    ${mediaMax.desktop`
        display: none !important;
    `};
  }
`;
