import { Newtype } from 'newtype-ts';

export type TutorialId = Newtype<{ readonly ID: unique symbol }, string> & string;

export enum TutorialURLLocation {
  ADMIN = 'ADMIN',
  BOARD = 'BOARD',
  ESTATEMATE = 'ESTATEMATE',
}

export const URLLocationLabel: Record<TutorialURLLocation, string> = {
  [TutorialURLLocation.ADMIN]: 'Espace administrateur',
  [TutorialURLLocation.BOARD]: 'Espace membre',
  [TutorialURLLocation.ESTATEMATE]: 'Espace bureau',
};

export interface Tutorial {
  id: TutorialId;
  location: TutorialURLLocation;
  link?: string | null;
}

export interface TutorialFormBody {
  tutorials: Array<Tutorial>;
}
