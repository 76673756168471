import React, { FC, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import logo from '@assets/logos/logo.svg';
import HeaderBurger from '@layout/header/burger/HeaderBurger';
import Nav from '@layout/header/nav/Nav';
import HeaderUser from '@layout/header/user/HeaderUser';
import { Profile } from '@modules/auth/model';
import { UserRole, userRoleColor, userRoleLabel } from '@shared/modules/users/model';

import * as Styled from './Header.styles';

function isBoard(role: UserRole, pathname: string) {
  return role === UserRole.Board && pathname.includes('board');
}

function displayRoleName(profile: Profile, locationPath: string): string | null {
  switch (profile.role) {
    case UserRole.Board:
      if (locationPath.includes('board')) {
        return userRoleLabel[profile.role];
      } else {
        return userRoleLabel[UserRole.Estatemate];
      }
    case UserRole.Operator:
      if (profile.isCompany) {
        return profile.companyLabel;
      } else {
        return userRoleLabel[profile.role];
      }
    default:
      return userRoleLabel[profile.role];
  }
}

interface HeaderProps {
  profile: Profile;
}

const Header: FC<HeaderProps> = ({ profile }) => {
  const location = useLocation();

  const [navOpen, setNavOpen] = useState<boolean>(false);

  const handleToggleNav = () => setNavOpen(old => !old);

  useEffect(() => {
    setNavOpen(false);
  }, [location.pathname]);

  return (
    <Styled.HeaderContainer>
      <Styled.HeaderTop>
        <Styled.HeaderTopContent>
          {profile.role !== UserRole.Operator && <HeaderBurger active={navOpen} handleToggle={handleToggleNav} />}

          <Link to={isBoard(profile.role, location.pathname) ? '/board' : ''}>
            <img src={logo} alt="ASL Community" />
          </Link>

          <Styled.Role background={userRoleColor[profile.role]}>
            {displayRoleName(profile, location.pathname)}
          </Styled.Role>

          <Styled.HeaderTopRight>
            <HeaderUser profile={profile} />
          </Styled.HeaderTopRight>
        </Styled.HeaderTopContent>
      </Styled.HeaderTop>
      {UserRole.Operator !== profile.role ? (
        <Nav role={profile.role} bioActivation={profile.bioActivation} open={navOpen} />
      ) : null}
    </Styled.HeaderContainer>
  );
};

export default Header;
