import { StyledConfig } from 'styled-components';

export function excludePropsFromConfig<O extends Object = {}>(
  propsKeys: Array<Extract<keyof O, any>>,
): StyledConfig<O> {
  return {
    shouldForwardProp: (prop, defaultValidatorFn) => !propsKeys.includes(prop) && defaultValidatorFn(prop),
  };
}

export function shouldForwardPropHelper<O extends object = {}>(
  prop: keyof O,
  defaultValidatorFn: (prop: keyof O) => boolean,
  filterProps: Array<keyof O | string>,
): boolean {
  return !filterProps.includes(prop) && defaultValidatorFn(prop);
}
