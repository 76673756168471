import styled from 'styled-components';

import background from '@assets/images/background.svg';

export const ErrorPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
  padding: 20px;
  overflow-y: auto;
  background: url(${background}) no-repeat center center;
  background-size: cover;

  > p {
    margin: 0;
    padding: 20px 0;
  }
`;
