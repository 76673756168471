import React, { FC } from 'react';
import * as Styled from './TutorialListHeader.styles';

const TutorialListHeader: FC = () => (
  <Styled.TutorialsListHeaderContainer>
    <p>URL</p>
    <p>Emplacement</p>
  </Styled.TutorialsListHeaderContainer>
);

export default TutorialListHeader;
