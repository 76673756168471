import { useFetchTask, useSendTask } from '@core/http/hooks';
import Page, { PageProps } from '@layout/page/Page';
import { useAuthContext } from '@modules/auth/context';
import { mapToPreventLeaveResult, useExternalSubmitButton } from '@shared/modules/form';
import * as ReferentialService from '@shared/modules/referential/service';
import { renderHttpRemoteData } from '@shared/utils/render';
import { SharedButton, SharedButtonLink } from '@styles/shared';
import * as E from 'fp-ts/Either';
import React, { FC } from 'react';
import EnhanceFormik from '../../../../shared/modules/form/components/EnhanceFormik';
import TutorialForm from './components/form/TutorialForm';
import { TutorialFormBody } from './model';
import { saveTutorialSchema } from './schema';
import * as TutorialsService from './service';

const TutorialsRoutePage: FC = () => {
  const [tutorials, handleRefresh] = useFetchTask(ReferentialService.getTutorials);
  const { requestUpdateProfile } = useAuthContext();

  const { formRef, handleSubmit, handleFormChanged, resetFormChanged, showCancelButton } = useExternalSubmitButton();

  const [loading, send] = useSendTask(TutorialsService.updateTutorials, {
    successMessage: 'Vos tutoriels ont bien été modifiés',
  });

  const pageProps: PageProps = {
    breadcrumbs: { title: 'Tutoriels' },
    bottom: {
      right: [
        <SharedButtonLink key="cancel" btnType="cancel" to={'/'} show={showCancelButton} />,
        <SharedButton key="save" btnType="save" onClick={handleSubmit} loading={loading} />,
      ],
    },
  };

  const handleSaveTutorials = (body: TutorialFormBody) => {
    return send(body.tutorials).then(res => {
      handleRefresh();
      requestUpdateProfile();

      if (E.isRight(res)) {
        resetFormChanged();
      }

      return mapToPreventLeaveResult(res);
    });
  };

  return (
    <Page {...pageProps}>
      {renderHttpRemoteData(tutorials, tutorials => (
        <EnhanceFormik
          ref={formRef}
          initialValues={{ tutorials }}
          onSubmit={handleSaveTutorials}
          onChanged={handleFormChanged}
          validationSchema={saveTutorialSchema}
          preventLeave>
          <TutorialForm />
        </EnhanceFormik>
      ))}
    </Page>
  );
};

export default TutorialsRoutePage;
