import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

import arrowActiveIcon from '@assets/icons/arrow-active.svg';
import arrowIcon from '@assets/icons/arrow.svg';
import stepActiveIcon from '@assets/icons/step-valid.svg';
import { primaryColor, tertiaryColor } from '@styles/utils';

export const PageStepsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  column-gap: 100px;
  background: #fff;
  padding: 20px 45px;
  margin-bottom: 10px;
`;

export const PageStepContainer = styled(NavLink)<{ $isValidate: boolean; $isClickable: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 15%;
  row-gap: 5px;
  align-items: center;
  text-align: center;
  color: ${tertiaryColor(500)};
  pointer-events: ${props => (props.$isClickable ? 'auto' : 'none')};

  &:hover {
    color: ${tertiaryColor(500)};
  }

  &.active {
    color: ${primaryColor(500)};
    font-weight: bold;

    > span {
      &:first-child {
        ${props =>
          props.$isValidate
            ? css`
                background: url(${stepActiveIcon}) no-repeat center;
              `
            : css`
                border: 2px dashed ${primaryColor(500)};
              `}

        > span {
          background: url(${arrowActiveIcon}) no-repeat center !important;
        }
      }
    }
  }

  > span {
    ${props =>
      props.$isValidate
        ? css`
            &:first-child {
              background: url(${stepActiveIcon}) no-repeat center;

              > span {
                background: url(${arrowActiveIcon}) no-repeat center !important;
              }
            }
          `
        : css`
            &:first-child {
              border: 2px dashed #a9aaaf;
            }
          `}
  }
`;

export const PageStepStatus = styled.span`
  position: relative;
  display: block;
  height: 30px;
  width: 30px;
  border-radius: 15px;
  margin-bottom: 5px;
`;

export const PageStepArrow = styled.span<{ show: boolean; numberOfSteps: number }>`
  display: ${props => (props.show ? 'initial' : 'none')};
  position: absolute;
  top: 10px;
  left: 7vw;
  height: 20px;
  width: 23px;
  background: url(${arrowIcon}) no-repeat center;
`;
