import styled from 'styled-components';
import { backgroundColor } from '@styles/utils';

export const ExportsPageContainer = styled.div`
  padding: 20px;
  display: grid;
  grid-template-columns: max-content max-content;
  align-items: center;
  column-gap: 12px;
  row-gap: 20px;
  background-color: ${backgroundColor()};
  border-radius: 10px;
`;

export const ExportsPageTitle = styled.p`
  margin-bottom: 0;
  font-size: 16px;
`;
