import { Routes, safeLazy } from '@core/router';
import { EstatemateProfile } from '@modules/auth/model';
import { renderConditional } from '@shared/utils/render';
import React, { FC } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useFetchTask } from '@core/http/hooks';
import * as DocumentService from './documents/service';
import { renderHttpRemoteData } from '@shared/utils/render';

const DashboardRoutes = safeLazy(() => import('./dashboard/routes'));
const ProfileRoutes = safeLazy(() => import('./profile/routes'));
const ShareComponent = safeLazy(() => import('./share/Shares'));
const DocumentsComponent = safeLazy(() => import('./documents/ListPage'));
const ArchivesComponent = safeLazy(() => import('./archives/ListPage'));
const VotesComponent = safeLazy(() => import('./votes/Votes'));
const ServicesRoutes = safeLazy(() => import('./service/routes'));
const PaymentRoutes = safeLazy(() => import('./payment/routes'));
const BioComponent = safeLazy(() => import('./bio/ListPage'));
const AssemblyRoutes = safeLazy(() => import('./assembly/routes'));

interface EstateMateRoutesProps {
  profile: EstatemateProfile;
  bioActivation: boolean;
}

const BoardRoutes: FC<EstateMateRoutesProps> = ({ profile, bioActivation }) => {
  const [getRootFolder] = useFetchTask(DocumentService.getRootFolder);

  return renderHttpRemoteData(getRootFolder, rootFolder => {
    const docPath = `/documents/${rootFolder.id}`;

    return (
      <Routes>
        <Route path="/shares">
          <ShareComponent />
        </Route>
        <Route path="/services" component={ServicesRoutes} />
        <Route path="/documents/:id" exact>
          <DocumentsComponent />
        </Route>
        <Route path="/documents">
          <Redirect to={docPath} />
        </Route>
        <Route path="/archives" component={ArchivesComponent} />
        <Route path="/profile">
          <ProfileRoutes profile={profile} />
        </Route>
        <Route path="/votes" component={VotesComponent} />
        <Route path="/payment/:id" component={PaymentRoutes} />
        <Route path="/" component={DashboardRoutes} exact />
        {renderConditional(bioActivation, () => (
          <Route path="/bio" component={BioComponent} />
        ))}
        <Route path="/assembly/attendance" component={AssemblyRoutes} exact />
      </Routes>
    );
  });
};

export default BoardRoutes;
