import styled, { css } from 'styled-components';

import background from '@assets/images/background.svg';
import { mediaMax } from '@styles/utils';

export const LayoutContainer = styled.div<{ estatemate: boolean }>`
  display: flex;
  flex-direction: column;
  height: 100%;

  ${props =>
    !props.estatemate &&
    css`
      ${mediaMax.desktop`
        display: none;
      `};
    `}
`;

export const LayoutContent = styled.div`
  height: 100%;
  flex: 1 1 auto;
  overflow: hidden;
`;

export const LockScreen = styled.div<{ estatemate: boolean }>`
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  opacity: 0.6;
  background: url(${background}) no-repeat center center;
  background-size: cover;

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 0 10px;
    row-gap: 15px;
    max-width: 650px;
    font-size: 18px;
  }

  ${props =>
    !props.estatemate &&
    css`
      ${mediaMax.desktop`
        display: flex;
      `};
    `}
`;
