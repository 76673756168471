import archives from '@assets/icons/archives.svg';
import coBio from '@assets/icons/coBio.svg';
import coPartage from '@assets/icons/coPartage.svg';
import coService from '@assets/icons/coService.svg';
import dashboard from '@assets/icons/dashboard.svg';
import documents from '@assets/icons/documents.svg';
import financier from '@assets/icons/financier.svg';
import home from '@assets/icons/home.svg';
import member from '@assets/icons/member.svg';
import operator from '@assets/icons/operator.svg';
import exportIcon from '@assets/icons/export.svg';
import { UserRole } from '@shared/modules/users/model';

export interface NavItem {
  title: string;
  to: string;
  icon: string;
  exact?: boolean;
}

const adminNav: Array<NavItem> = [
  {
    title: 'Tableau de bord',
    to: '/',
    icon: dashboard,
    exact: true,
  },
  {
    title: 'Opérateurs',
    to: '/operators',
    icon: operator,
  },
  {
    title: 'Opérations immobilières',
    to: '/estates',
    icon: home,
  },
  {
    title: 'Exports',
    to: '/exports',
    icon: exportIcon,
  },
];

const estatemateNav: Array<NavItem> = [
  {
    title: 'Tableau de bord',
    to: '/',
    icon: dashboard,
    exact: true,
  },
  {
    title: 'Co bio',
    to: '/bio',
    icon: coBio,
  },
  {
    title: 'Co service',
    to: '/services',
    icon: coService,
  },
  {
    title: 'Co partage',
    to: '/shares',
    icon: coPartage,
  },
  {
    title: 'Documents',
    to: '/documents',
    icon: documents,
  },
  {
    title: 'Archives',
    to: '/archives',
    icon: archives,
  },
];

const boardNav: Array<NavItem> = [
  {
    title: 'Tableau de bord',
    to: '/board',
    icon: dashboard,
    exact: true,
  },
  {
    title: 'Mon opération immobilière',
    to: '/board/estate',
    icon: home,
  },
  {
    title: 'Financier',
    to: '/board/finance',
    icon: financier,
  },
  {
    title: 'Co bio',
    to: '/board/bio',
    icon: coBio,
  },
  {
    title: 'Co partage',
    to: '/board/shares',
    icon: coPartage,
  },
  {
    title: 'Documents',
    to: '/board/documents',
    icon: documents,
  },
  {
    title: 'Membres',
    to: '/board/estatemates',
    icon: member,
  },
  {
    title: 'Archives',
    to: '/board/archives',
    icon: archives,
  },
];

export const navItems: Record<Exclude<UserRole, UserRole.Operator>, Array<NavItem>> = {
  [UserRole.Admin]: adminNav,
  [UserRole.Estatemate]: estatemateNav,
  [UserRole.Board]: boardNav,
};
