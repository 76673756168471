/**
 * Remove non ASCII chars
 *
 * @param name
 */
export function normalizeFileName(name: string) {
  // [\x20-\x7F] keep only ascii
  // [\u0300-\u036f] keep accents (with normalize NFD)
  return name
    .normalize('NFD')
    .replace(/[^\x20-\x7F\u0300-\u036f]/g, '')
    .normalize('NFC');
}

export function createFormData(data: Record<string, string | Blob>): FormData {
  const formData = new FormData();

  Object.keys(data).forEach(key => {
    const value = data[key];

    if (value instanceof File) {
      formData.append(key, value, normalizeFileName(value.name));
    } else {
      formData.append(key, value);
    }
  });

  return formData;
}
