import React, { FC } from 'react';

import { Header, Modal } from 'semantic-ui-react';
import { SharedButton } from '@styles/shared';

interface FormikPreventLeaveModalProps {
  open: boolean;
  submitting: boolean;
  onClose: () => void;
  onLeave: () => void;
  onSubmitAndLeave: () => void;
}

const FormikPreventLeaveModal: FC<FormikPreventLeaveModalProps> = ({
  open,
  submitting,
  onClose,
  onLeave,
  onSubmitAndLeave,
}) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      closeOnDimmerClick={false}
      closeOnDocumentClick={false}
      closeIcon
      size="tiny"
      dimmer="inverted">
      <Header content="Modifications en cours" />
      <Modal.Content>
        <p>Si vous quittez maintenant cette page, vos modifications en cours ne seront pas enregistrées.</p>
      </Modal.Content>
      <Modal.Actions>
        <SharedButton btnType="cancel" content="Quitter maintenant" onClick={onLeave} disabled={submitting} />
        <SharedButton
          btnType="save"
          content="Enregister et quitter"
          onClick={onSubmitAndLeave}
          loading={submitting}
          disabled={submitting}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default FormikPreventLeaveModal;
