import { Routes } from '@core/router';
import React, { FC } from 'react';
import { Route } from 'react-router-dom';
import TutorialsRoutePage from './ListPage';

const TutorialsRoute: FC = () => (
  <Routes>
    <Route path="/tutorials" component={TutorialsRoutePage} />
  </Routes>
);

export default TutorialsRoute;
