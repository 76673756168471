import { Newtype } from 'newtype-ts';
import { EstatemateId } from '@shared/modules/estatemate/model';

export type UserId = Newtype<{ readonly ID: unique symbol }, string> & string;

export enum UserRole {
  Admin = 'admin',
  Operator = 'operator',
  Estatemate = 'estatemate',
  Board = 'board',
}

export const userRoleLabel: Record<UserRole, string> = {
  [UserRole.Admin]: 'Administrateur',
  [UserRole.Operator]: 'Opérateur',
  [UserRole.Estatemate]: 'Membre',
  [UserRole.Board]: 'Bureau',
};

export const userRoleColor: Record<UserRole, string> = {
  [UserRole.Admin]: '#587bcc',
  [UserRole.Operator]: '#343d6f',
  [UserRole.Estatemate]: '#01956c',
  [UserRole.Board]: '#f29814',
};

export interface CreateUserParams {
  email: string;
  firstName: string;
  lastName: string;
}

export interface UpdateUserParams {
  firstName: string;
  lastName: string;
  phone?: string | null;
  address?: UserAddress;
}

export interface UserAddress {
  address?: string;
  postalCode?: string;
  city?: string;
}

export interface UpdateUserEmailParams {
  email: string;
}

export interface UpdateUserEmailFormBody {
  email: string;
  confirmEmail: string;
}

export interface UpdateUserPasswordParams {
  oldPassword: string;
  newPassword: string;
}

export interface UpdateUserPasswordFormBody {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
}

export interface EstatemateVoiceNumber {
  id: EstatemateId;
  afterVoiceNumber: number;
  beforeVoiceNumber: number;
  estateNumber: number;
  firstName: string;
  lastName: string;
  hasChanged: boolean;
  isMacroLot: boolean;
}

export interface UpdateVoiceNumberFormBody {
  overriddenVoiceNumber: number | null;
}

export interface UpdateSubscriptionNumberFormBody {
  overriddenSubscriptionNumber: number | null;
}
