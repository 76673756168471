import React, { FC, Suspense } from 'react';
import { Route as ReactRoute, Switch } from 'react-router-dom';
import { DebouncedLineLoader } from '@layout/loaders/line-loader/LineLoader';
import ErrorPage from '@shared/components/error-page/ErrorPage';
import { HttpError } from '@core/http';
import * as Sentry from '@sentry/react';

export const Routes: FC = ({ children }) => (
  <Sentry.ErrorBoundary fallback={<ErrorPage error={HttpError.default} />}>
    <Suspense fallback={<DebouncedLineLoader />}>
      <Switch>
        {children}
        <ReactRoute component={ErrorPage} />
      </Switch>
    </Suspense>
  </Sentry.ErrorBoundary>
);
