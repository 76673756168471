import { pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';

export function camelToSnake(value: string): string {
  return value.replace(/[\w]([A-Z])/g, m => m[0] + '_' + m[1]).toLowerCase();
}

export function snakeToCamel(value: string): string {
  return value.replace(/(_\w)/g, m => m[1].toUpperCase());
}

export function filterEmptyStringToOption<T extends string>(value: T | null | undefined): O.Option<T> {
  return pipe(
    O.fromNullable(value),
    O.filter(v => v !== ''),
  );
}

export function filterEmptyStringToNullable<T extends string>(value: T | null | undefined): string | null {
  return O.toNullable(filterEmptyStringToOption(value));
}

export function removeEmptyString<T extends unknown>(value: T): T {
  if (value === '') {
    return null as any;
  } else if (Array.isArray(value)) {
    return value.map(item => removeEmptyString(item)) as any;
  } else if (value && typeof value === 'object') {
    return Object.keys(value as any).reduce(
      (acc, curr) => ({
        ...acc,
        [curr]: removeEmptyString((value as any)[curr]),
      }),
      {},
    ) as any;
  }

  return value;
}
